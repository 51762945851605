import React from 'react';
import './home.css'; // Asegúrate de importar tus estilos CSS
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from 'react-bootstrap/Card';
import { Container, Row, Col, Button } from 'react-bootstrap';
import imagenInstagram1 from '../../images/imagen-1.png';
import imagenInstagram2 from '../../images/imagen-2.png';
import imagenInstagram3 from '../../images/imagen-3.png';
import imagenFono from '../../images/fono-imagen-home.jpeg';
import imagenTOAdult from '../../images/imagen-to-adulto.jpg';
import imagenSpeechAdult from '../../images/senior-speech-therapy.jpg';
import imagenPerfilSensorial from '../../images/terapia-ocupacional-sensorial.jpg';
import imagenHero1 from '../../images/centro-2.jpeg';
import imagenHero2 from '../../images/niñas-7.jpeg';
import imagenHero3 from '../../images/imagen-portada-2.jpg';
import imagenHero4 from '../../images/centro-3.jpeg';
import proffesional1 from '../../images/profesionales-1-nobg.png';
import proffesional2 from '../../images/profesionales-4-nobg.png';
import proffesional3 from '../../images/profesionales-5-nobg.png';
import proffesional4 from '../../images/profesionales-3-nobg.png';
import proffesional5 from '../../images/profesionales-2-nobg.png';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FaClinicMedical, FaWifi } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imagenTO from '../../images/occuptaional-kid-session.jpg'
import { Fade } from 'react-awesome-reveal';

function Home() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };


    return (


        <>
            <Fade>
                <Row>
                    <div className="hero-image">
                        <Slider {...settings}>
                            <div>
                                <img src={imagenHero1} alt="Imagen 1" className="hero-slider-image" />
                            </div>
                            <div>
                                <img src={imagenHero4} alt="Imagen 1" className="hero-slider-image" />
                            </div>
                            <div>
                                <img src={imagenHero2} alt="Imagen 2" className="hero-slider-image" />
                            </div>
                            <div>
                                <img src={imagenHero3} alt="Imagen 3" className="hero-slider-image" />
                            </div>
                        </Slider>
                        <div className="hero-overlay"></div>
                        <div className="hero-text">
                            <h1 className='display-4 fw-bold text-sm-start text-md-start text-lg-start text-xl-start'>¡Bienvenidos a Centro Kaili!</h1>
                            <p className='fs-2 fs-md-4 fs-lg-3'>Centro Terapéutico Integral para todo el ciclo vital</p>
                            <p className='fs-3 fs-md-4 fs-lg-3'>¿Tienes dudas o preguntas?</p>
                            <Button className='custom-button btn-lg text-black'>
                                <a href="https://api.whatsapp.com/message/WQHB6Z6KSCPOJ1?autoload=1&app_absent=0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    CONSULTA AQUÍ
                                </a>
                            </Button>
                        </div>
                    </div>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-3 fw-bold' style={{ color: '#219ebc' }}>Nuestros servicios</h2>
                    <hr style={{ backgroundColor: 'yellow', height: '3px', border: 'none' }} />
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Container className='mt-5'>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex justify-content-center'>
                            <Card className="h-100 card-custom">
                                <Card.Img variant="top" src={imagenTO} width="100%" height="auto" />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-5 fw-bold text-center'>Evaluación e intervención de Terapia Ocupacional para niños y adolescentes</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex justify-content-center'>
                            <Card className="h-100 card-custom">
                                <Card.Img variant="top" src={imagenFono} width="100%" height="auto" />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-5 fw-bold text-center'>Evaluación e intervención de Fonoaudiología para niños y adolescentes</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-4'>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex justify-content-center'>
                            <Card className="h-100 card-custom">
                                <Card.Img variant="top" src={imagenTOAdult} width="100%" height="auto" />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-5 fw-bold text-center'>Evaluación e intervención de Terapia Ocupacional para adultos y personas mayores</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex justify-content-center'>
                            <Card className="h-100 card-custom">
                                <Card.Img variant="top" src={imagenSpeechAdult} width="100%" height="auto" />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-5 fw-bold text-center'>Evaluación e intervención de Fonoaudiología para adultos y personas mayores</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-4'>
                        <Col xs={12} md={6} lg={6} className='mb-4 d-flex justify-content-center'>
                            <Card className="h-100 card-custom">
                                <Card.Img variant="top" src={imagenPerfilSensorial} width="100%" height="auto" />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-5 fw-bold text-center'>Evaluación del Perfil Sensorial en adolescentes y adultos</Card.Title>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {/* <Row className='mt-5'></Row>
            <Row className='mt-5'></Row> */}

                {/* <Row className='mt-5'>
                <h2 className='text-center display-5 fw-bold' style={{ color: '#219ebc' }}>Consulta por nuestros planes en las <b>sesiones de intervención</b></h2>
            </Row>

            <Row className='mt-5'></Row>
            <Row className='mt-5'></Row> */}

                {/* <Container>
                <Row className='mt-5 text-center'>
                    <Col xs={12} md={4} lg={4} className='mx-auto justify-content-center mb-3' style={{ backgroundColor: '#66D5E5', padding: '15px', borderRadius: '50px' }}>
                        <h1 className='fs-2 fw-bold'>Pack de 4 sesiones al mes</h1>
                    </Col>
                    <Col xs={12} md={4} lg={4} className='mx-auto justify-content-center mb-3' style={{ backgroundColor: '#66D5E5', padding: '15px', borderRadius: '50px' }}>
                        <h1 className='fs-2 fw-bold'>Pack de 8 sesiones al mes</h1>
                    </Col>
                </Row>
            </Container> */}

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-3 fw-bold' style={{ color: '#219ebc' }}>Modalidad de atención</h2>
                    <hr style={{ backgroundColor: 'yellow', height: '3px', border: 'none' }} />
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Container>
                    <Row className='mt-5 text-center'>
                        <Col xs={12} md={6} lg={6} className='mx-auto justify-content-center mb-3'>
                            <div style={{ textAlign: 'center' }}>
                                <FaClinicMedical size='10em' style={{ color: '#F28B82' }} />
                                <h3 className='fs-4 mt-4 fw-bold'>Atención presencial en centro terapéutico a pasos del metro Manquehue</h3>
                            </div>
                        </Col>

                        <Col xs={12} md={6} lg={6} className='mx-auto justify-content-center mb-3'>
                            <div style={{ textAlign: 'center' }}>
                                <FaWifi size='10em' style={{ color: '#A7E8AF' }} />
                                <h3 className='fs-4 mt-4 fw-bold'>Atención online a personas desde los 15 años en adelante</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5'>
                    <h2 className='text-center display-3 fw-bold' style={{ color: '#219ebc' }}>Nuestro equipo</h2>
                    <hr style={{ backgroundColor: 'yellow', height: '3px', border: 'none' }} />
                </Row>

                <Container className='mt-5'>
                    <Row className='justify-content-center'>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="">
                                <Card.Img variant="top" src={proffesional1} width="100%" height="auto" style={{ objectFit: 'cover', height: '450px' }} />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-4 fw-bold text-center'>Macarena Guede</Card.Title>
                                    <Card.Text className='text-center' style={{ color: '#2D2D2D !important' }}>
                                        Fundadora de Centro Kaili y Terapeuta Ocupacional dedicada a la geriatría y gerontología, especialmente en trastornos neurocognitivos.
                                        Cuenta con experiencia en el trabajo con personas mayores autovalentes, con deterioro cognitivo y con distintos tipos de demencia.
                                    </Card.Text>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="">
                                <Card.Img variant="top" src={proffesional2} width="100%" height="auto" style={{ objectFit: 'cover', height: '450px' }} />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-4 fw-bold text-center'>Antonia Salinas</Card.Title>
                                    <Card.Text className='text-center' style={{ color: '#2D2D2D !important' }}>
                                        Terapeuta Ocupacional dedicada al área infanto-juvenil. Tiene experiencia con niños(as) y adolescentes, abordando diversos desafíos en alimentación,
                                        conducta, disfunción en el procesamiento sensorial, etc. Disfunción en el procesamiento sensorial y trastornos del neurodesarrollo (Condición del Espectro Autista, Trastorno por déficit de Atención con o sin hiperactividad (TDA-H)), 
                                        Síndrome de Down y retrasos en el desarrollo psicomotor. Además, se dedica a la estimulación temprana.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="">
                                <Card.Img variant="top" src={proffesional3} width="100%" height="auto" style={{ objectFit: 'cover', height: '450px' }} />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-4 fw-bold text-center'>Camila Acevedo</Card.Title>
                                    <Card.Text className='text-center'>
                                        Fonoaudióloga dedicada al área infanto-juvenil. Camila tiene experiencia en atención temprana en desafíos de comunicación como el desarrollo del
                                        lenguaje, habla y comunicación. Ademas, aborda la alimentación complementaria y desafíos en alimentación como selectividad y rechazo alimentario.   
                                    </Card.Text>
                                    {/* centro terapéutico integral para todo el ciclo vital */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="">
                                <Card.Img variant="top" src={proffesional4} width="100%" height="auto" style={{ objectFit: 'cover', height: '450px' }} />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-4 fw-bold text-center'>María Ignacia Carvallo</Card.Title>
                                    <Card.Text className='text-center'>
                                        Terapeuta Ocupacional dedicada a población adolescente, adulta y personas mayores. Tiene formación y experiencia en salud mental y 
                                        psiquiatría, neurodivergencia y trastornos neurocognitivos. Se enfoca en brindar atención integral de Terapia Ocupacional 
                                        especializada en cada usuario(a), sus necesidades, interes y contextos de desempeño, trabajando estrechamente con sus redes de apoyo
                                        principales.
                                        Realiza evaluación del Perfil Sensorial en adolescentes y adultos.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} lg={4} className='mb-4 d-flex'>
                            <Card className="">
                                <Card.Img variant="top" src={proffesional5} width="100%" height="auto" style={{ objectFit: 'cover', height: '450px' }} />
                                <Card.Body style={{ backgroundColor: '#D0F0FD' }}>
                                    <Card.Title className='fs-4 fw-bold text-center'>Stefany Arévalo</Card.Title>
                                    <Card.Text className='text-center'>
                                        Terapeuta Ocupacional dedicada al área infanto-juvenil. Tiene experiencia en atención clínica y en programas de integración escolar (PIE). Actualmente se encuentra realizando certificación en integración sensorial.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Row className='mt-5'>
                    <h2 className='text-center display-3 fw-bold' style={{ color: '#219ebc' }}>Nuestro contenido en Instagram</h2>
                    <hr style={{ backgroundColor: 'yellow', height: '3px', border: 'none' }} />
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>

                <Row className='mt-5 justify-content-center'>
                    <Col md={3}>
                        <Card>
                            <a href='https://www.instagram.com/p/C3QGyIzuepp/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram1} />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <a href='https://www.instagram.com/p/C3ctkQNuE-y/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram3} />
                            </a>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <a href='https://www.instagram.com/p/C30kPqzpmUe/?img_index=1' target='_blank' rel='noopener noreferrer'>
                                <Card.Img variant='' src={imagenInstagram2} />
                            </a>
                        </Card>
                    </Col>
                </Row>

                <Row className='mt-5'></Row>

                <Row className='mt-3 mb-5 justify-content-center'>
                    <Col xs="auto">
                        <Button variant="info" size='lg' href="https://www.instagram.com/centro.kaili/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="mr-2" /> Síguenos en Instagram
                        </Button>
                    </Col>
                </Row>

                <Row className='mt-5'></Row>
                <Row className='mt-5'></Row>
            </Fade>
        </>
    );
}

export default Home;
