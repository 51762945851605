
import { Container, Row, Col, Card } from "react-bootstrap";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './what-we-do.css';
import { Fade } from "react-awesome-reveal";
// import imagen from '../../images/imagen-1.png';
// import imagen3 from '../../images/imagen-3.png';

function WhatWeDo() {

    return (
        <>
            <Fade>
                <Container fluid>


                    <Row className="text-center justify-content-center mb-5">
                        <Col></Col>
                        <Col md={8}>
                            <h1 className="mb-4 mt-5 display-6 fw-bold" style={{ color: '#219ebc' }}>¿Qué hacemos?</h1>
                            <p className="display-6 fs-4">Entregamos una atención personalizada de Terapia Ocupacional y Fonoaudiología en todo ciclo vital,
                                centrándonos en las necesidades de cada persona y familia en particular. Nuestro principal foco es el cuidado de
                                la Salud Mental, centrándonos principalmente en el abordaje de las siguientes áreas, situaciones de salud y desafíos/dificultades:
                            </p>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row></Row>
                    <Row></Row>

                    <Row className="row justify-content-center mb-5">

                        <Col></Col>
                        <Col className="items-center">
                            <h1 className="text-center fs-4 fw-bold mb-2" style={{ backgroundColor: '#66D5E5', padding: '10px', borderRadius: '50px' }}>ABORDAJE INFANTO-JUVENIL</h1>
                            <Card style={{ width: '35rem', border: 'none', borderRadius: '50px' }}>
                                <Card.Body style={{ backgroundColor: '#FFFAF6', border: 'white', borderRadius: '50px' }}>
                                    {/* <Card.Title className="fs-4 text-center fw-bold mb-4" style={{ color: 'black' }}>ABORDAJE INFANTO-JUVENIL</Card.Title> */}
                                    <Card.Text>
                                        <div style={{ fontSize: '18px' }}>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Estimulación temprana
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Retraso en el desarrollo psicomotor
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Condición del Espectro Autista
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Síndrome De Down
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Trastorno por déficit de atención con o sin hiperactividad
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Desafíos conductuales y/o socioemocionales
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Dificultades en el procesamiento sensorial
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Rechazo y selectividad alimentaria
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Dificultades en Actividades de la Vida Diaria e Independencia
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Dificultades en habilidades cognitivas
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Dificultades en habilidades sociales
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Dificultades en el desempeño escolar
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Trastornos del lenguaje y comunicación
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Asesorías y orientación a padres y/o cuidadores
                                            </div>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <h1 className="text-center fs-4 fw-bold mb-2" style={{ backgroundColor: '#66D5E5', padding: '10px', borderRadius: '50px' }}>ABORDAJE ADULTOS-PERSONAS MAYORES</h1>
                            <Card style={{ width: '35rem', height: '30rem', border: 'none', borderRadius: '50px' }}>
                                <Card.Body style={{ backgroundColor: '#FFFAF6', borderRadius: '50px' }}>
                                    {/* <Card.Title className="fs-4 text-center fw-bold mb-4" style={{ backgroundColor: '#da627d', padding: '10px', borderRadius: '50px'}}>ABORDAJE ADULTOS-PERSONAS MAYORES</Card.Title> */}
                                    <Card.Text>
                                        <div style={{ fontSize: '18px' }}>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Estimulación cognitiva
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Trastorno neurocognitivo menor (deterioro cognitivo leve)
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Trastorno neurocognitivo mayor (demencia)
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Condición del Espectro Autista
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Percepción negativa de Salud Mental
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Hábitos y estructuración de rutinas
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Entrenamiento en Actividades de la Vida Diaria e Independencia
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Asesorías en modificación ambiental
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Entrenamiento y fortalecimiento en habilidades para la vida
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Resignificación de roles y ocupaciones
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Fortalecimiento de habilidades sociales y socioemocionales
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Alteraciones del lenguaje y comunicación
                                            </div>
                                            <div className="flex" style={{ marginBottom: '1%' }}>
                                                <FontAwesomeIcon icon={faCircle} color="blue mr-3" style={{ marginRight: '1%', color: '#4798A4', width: '9px' }} />Orientación y educación a familiares y/o cuidadores
                                            </div>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col></Col>
                    </Row>

                </Container>
            </Fade>
        </>
    )
}

export default WhatWeDo;